import { Navbar, NavDropdown } from 'react-bootstrap';
import MenuProfile from './MenuProfile';
import ThemeSwitcher from './ThemeSwitcher';
import { Link } from 'react-router-dom';

const LayoutMaster = (props) => {
    return (
        <div className='app'>
            <header>
                <Navbar expand='sm' className='box-shadow mb-1'>
                    <div className='container-fluid inside-block'>
                        <Navbar.Brand as={Link} to='/'>
                            <img
                                src='/images/Brand.svg'
                                height='20'
                                className='d-inline-block align-baseline'
                                alt='React Bootstrap logo'
                            />
                        </Navbar.Brand>
                        <button
                            className='navbar-toggler'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='.navbar-collapse'
                            aria-controls='navbarSupportedContent'
                            aria-expanded='false'
                            aria-label='Toggle navigation'
                        >
                            <span className='navbar-toggler-icon'></span>
                        </button>
                        <div className='navbar-collapse collapse d-sm-inline-flex justify-content-between'>
                            <ul className='navbar-nav flex-grow-1 nav-menu'>
                                <NavDropdown title='Биржа' id='basic-nav-dropdown'>
                                    <NavDropdown.Item className='position-relative'>Создать заявку</NavDropdown.Item>
                                    <NavDropdown.Item className='position-relative'>Заявки заказчиков</NavDropdown.Item>
                                </NavDropdown>
                                <li className='nav-item'>
                                    <a className='nav-link' href='#'>
                                        Предложения от исполнителей
                                    </a>
                                </li>
                                <li className='nav-item'>
                                    <a className='nav-link' href='#'>
                                        Новости
                                    </a>
                                </li>
                                <li className='nav-item'>
                                    <a className='nav-link' href='#'>
                                        Поддержка
                                    </a>
                                </li>
                            </ul>
                            <ul className='navbar-nav'>
                                <li className='nav-item me-2'>
                                    <button className='btn btn-outline-secondary btn-icon'>
                                        <img src='/images/icons/search.png' height='16' />
                                    </button>
                                </li>
                                <MenuProfile />
                                <ThemeSwitcher />
                            </ul>
                        </div>
                    </div>
                </Navbar>
            </header>
            {props.children}
            {/*<footer className='footer container-fluid mt-3'>
                <div className='row'>
                    <div className='col-md-4'></div>
                    <div className='col-md-4'>
                        <div className='row'>
                            <div className='col-md-3'>
                                <div>Биржа</div>
                                <div>Предложение исполнителей</div>
                                <div>Предложение заказчиков</div>
                            </div>
                            <div className='col-md-3'>
                                <div>Служба поддержки</div>
                            </div>
                            <div className='col-md-3'>
                                <div>Новости</div>
                            </div>
                            <div className='col-md-3'></div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div>
                            <a
                                asp-area=''
                                asp-controller='Home'
                                asp-action='Privacy'
                            >
                                Политика конфиденциальности
                            </a>
                        </div>
                        <div>&copy; ToothTech 2024 (v.0.3)</div>
                    </div>
                </div>
            </footer>*/}
        </div>
    );
};

export default LayoutMaster;
