import { Button, Modal } from 'react-bootstrap';

const ChangeSaveStatusOrderModal = (props) => {
    const { isOpen, createHandle, saveHandle, closeModal } = props;

    const handleClose = () => closeModal();

    return (
        <Modal show={isOpen} onHide={handleClose} backdrop='static' size='md' className='modal-delete' centered>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body className='text-center py-2'>
                Заказ будет опубликован на бирже
                <p className='text-modal mb-3'>Если вы пока не хотите публиковать его, то нажмите «Сохранить»</p>
            </Modal.Body>
            <Modal.Footer className='justify-content-center'>
                <Button variant='primary' onClick={createHandle} className='m-0 me-4'>
                    Создать
                </Button>
                <Button variant='outline-primary' onClick={saveHandle} className='m-0'>
                    Сохранить
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ChangeSaveStatusOrderModal;
