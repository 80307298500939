import { apiSlice } from './apiSlice';

export const patientApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getMyPatients: builder.query({
            query: (workzoneId) => 'api/patients?workzoneId=' + workzoneId,
            providesTags: () => [{ type: 'Patients', id: 'List' }],
        }),
        createPatient: builder.mutation({
            query: (data) => ({
                url: 'api/patients',
                method: 'POST',
                body: { ...data },
            }),
            invalidatesTags: [{ type: 'Patients', id: 'List' }],
        }),
    }),
});

export const {
    useGetMyPatientsQuery,
    useLazyGetMyPatientsQuery,
    useCreatePatientMutation,
} = patientApiSlice;
