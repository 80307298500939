import { Col, Form, Row } from 'react-bootstrap';
import OrderFilesAccordion from '../OrderFilesAccordion';
import { useState } from 'react';
import OrderFileDownloader from '../OrderFileDownloader';
import { useDeleteDocMutation, useDownloadDocMutation } from '../../../../app/api/orderApiSlice';

const AccordionPhoto = (props) => {
    const { files, fileTypes, orderId } = props;

    const [downloadFile] = useDownloadDocMutation();
    const [deleteDoc] = useDeleteDocMutation();

    const [selectedType, setSelectedType] = useState('0');

    const downloadScan = async (fileId) => {
        const file = await downloadFile(fileId);
        const a = document.createElement('a');
        a.href = file.data.url;
        a.download = files.find((x) => x.id === fileId).fileName;
        a.click();
        URL.revokeObjectURL(file.data.url);
    };

    const deleteFile = async (fileId) => {
        await deleteDoc(fileId);
    };

    return (
        <OrderFilesAccordion title='Фото'>
            <Form.Group as={Row} className='mb-3' controlId='fileTypeScan'>
                <Form.Label column md={4}>
                    Название файла
                </Form.Label>
                <Col md={8}>
                    <Form.Select value={selectedType} onChange={(e) => setSelectedType(e.target.value)}>
                        <option value='0'>Выберите файл</option>
                        {fileTypes?.map((item) => (
                            <option
                                key={item.id}
                                value={item.id}
                                disabled={files?.some((x) => x.typeId === item.id && x.typeId !== 3)}
                            >
                                {item.name}
                            </option>
                        ))}
                    </Form.Select>
                </Col>
            </Form.Group>
            {parseInt(selectedType) !== 0 && (
                <OrderFileDownloader
                    infoText='Файлы могут быть в формате: .jpg, .png. Максимальный размер 10 МБ.'
                    fileType={parseInt(selectedType)}
                    orderId={orderId}
                    accept={{ 'image/jpeg': ['.jpg', '.png'] }}
                />
            )}
            {files?.length > 0 && (
                <div className='order-files-block mt-4 mb-3'>
                    <div className='order-files-title mb-2'>Прикреплённые файлы</div>
                    <div>
                        {files.map((item) => (
                            <div key={item.id} className='mb-2 d-flex justify-content-between align-items-center'>
                                <div className='order-file'>{item.fileName}</div>
                                <div>
                                    <button className='btn order-file-action-btn' onClick={() => downloadScan(item.id)}>
                                        Скачать
                                    </button>
                                    <button className='btn order-file-action-btn' onClick={() => deleteFile(item.id)}>
                                        Удалить
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </OrderFilesAccordion>
    );
};

export default AccordionPhoto;
