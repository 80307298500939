import { Col, Container, Nav, Row } from 'react-bootstrap';
import LayoutMaster from './LayoutMaster';
import { Link, useLocation } from 'react-router-dom';
import { logout } from '../../services/AuthService';
import { useEffect, useState } from 'react';

const LayoutProfile = (props) => {
    const location = useLocation();

    const [currentMenuItem, setCurrentMenuItem] = useState('profile');
    console.log('location', location);

    useEffect(() => {
        switch (location.pathname) {
            case '/lk/workzone':
                setCurrentMenuItem('workzone');
                break;
            case '/lk/profile':
                setCurrentMenuItem('profile');
                break;

            default:
                setCurrentMenuItem('profile');
                break;
        }
    }, [location]);

    return (
        <LayoutMaster>
            <Container>
                <Row>
                    <Col md={3}>
                        <div className='lk-menu'>
                            <div className='lk-menu-header text-center'>
                                Личный кабинет
                            </div>
                            <Nav
                                activeKey={currentMenuItem}
                                className='flex-column'
                            >
                                <Nav.Link
                                    as={Link}
                                    to='/lk/workzone'
                                    eventKey='workzone'
                                    className='d-flex align-items-center justify-content-between'
                                >
                                    <span>
                                        <img
                                            src='/images/icons/grid-2.svg'
                                            width={32}
                                            className='align-top me-2'
                                        />
                                        Рабочая зона
                                    </span>
                                    <img
                                        src='/images/icons/arrow-down.svg'
                                        width={20}
                                    />
                                </Nav.Link>
                                <Nav.Link
                                    as={Link}
                                    to='/lk/orders'
                                    disabled
                                    className='d-flex align-items-center justify-content-between'
                                >
                                    <span>
                                        <img
                                            src='/images/icons/order.svg'
                                            width={32}
                                            className='align-top me-2'
                                        />
                                        Заказы
                                    </span>
                                    <img
                                        src='/images/icons/arrow-down.svg'
                                        width={20}
                                    />
                                </Nav.Link>
                                <hr />
                                <Nav.Link
                                    as={Link}
                                    to='/lk/profile'
                                    eventKey='profile'
                                    className='d-flex align-items-center justify-content-between'
                                >
                                    <span>
                                        <img
                                            src='/images/icons/user.svg'
                                            width={32}
                                            className='align-top me-2'
                                        />
                                        Профиль
                                    </span>
                                    <img
                                        src='/images/icons/arrow-down.svg'
                                        width={20}
                                    />
                                </Nav.Link>
                                <Nav.Link
                                    as={Link}
                                    to='/lk/wallet'
                                    disabled
                                    className='d-flex align-items-center justify-content-between'
                                >
                                    <span>
                                        <img
                                            src='/images/icons/wallet-2.svg'
                                            width={32}
                                            className='align-top me-2'
                                        />
                                        Кошелёк
                                    </span>
                                    <img
                                        src='/images/icons/arrow-down.svg'
                                        width={20}
                                    />
                                </Nav.Link>
                                <Nav.Link
                                    as={Link}
                                    to='/lk/messages'
                                    disabled
                                    className='d-flex align-items-center justify-content-between'
                                >
                                    <span>
                                        <img
                                            src='/images/icons/message-text.svg'
                                            width={32}
                                            className='align-top me-2'
                                        />
                                        Сообщения
                                    </span>
                                    <img
                                        src='/images/icons/arrow-down.svg'
                                        width={20}
                                    />
                                </Nav.Link>
                                <Nav.Link
                                    as={Link}
                                    to='/lk/helpdesk'
                                    disabled
                                    className='d-flex align-items-center justify-content-between'
                                >
                                    <span>
                                        <img
                                            src='/images/icons/message-question.svg'
                                            width={32}
                                            className='align-top me-2'
                                        />
                                        Служба поддержки
                                    </span>
                                    <img
                                        src='/images/icons/arrow-down.svg'
                                        width={20}
                                    />
                                </Nav.Link>
                                <hr />
                                <Nav.Link
                                    onClick={logout}
                                    className='d-flex align-items-center justify-content-between'
                                >
                                    <span>
                                        <img
                                            src='/images/icons/logout.svg'
                                            width={32}
                                            className='align-top me-2'
                                        />
                                        Выйти
                                    </span>
                                    <img
                                        src='/images/icons/arrow-down.svg'
                                        width={20}
                                    />
                                </Nav.Link>
                            </Nav>
                        </div>
                    </Col>
                    <Col>{props.children}</Col>
                </Row>
            </Container>
        </LayoutMaster>
    );
};

export default LayoutProfile;
