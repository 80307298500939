import { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';

const AddDetailsModal = (props) => {
    const { isOpen, closeModal, toothNumbers, comments, index = -1, save } = props;

    const [changedTooths, setChangedTooths] = useState([]);
    const [description, setDescription] = useState('');
    const [errorDesc, setErrorDesc] = useState('');

    useEffect(() => {
        let arr = [];
        toothNumbers?.map((item) => arr.push(item));
        setChangedTooths(arr);
        setDescription(comments);
    }, [toothNumbers, index, comments]);

    const handleClose = () => closeModal();

    const changeTooth = (tooth) => {
        if (!changedTooths.some((x) => x === tooth)) {
            setChangedTooths([...changedTooths, tooth]);
        }
    };

    const deleteTooth = (tooth) => {
        setChangedTooths(changedTooths.filter((x) => x !== tooth));
    };

    const clearAll = () => {
        setChangedTooths([]);
    };

    const saveHandle = () => {
        if (description === '') {
            setErrorDesc('Поле комментарий не заполнено');
        } else {
            setErrorDesc('');
            setDescription('');
            save({ description, teeth: changedTooths }, index);
        }
    };

    return (
        <Modal show={isOpen} onHide={handleClose} backdrop='static' size='lg' className='add-details-modal' centered>
            <Modal.Header closeButton>
                <Modal.Title>Детали</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={7}>
                        <Row className='mb-2'>
                            <Col className='d-flex justify-content-between'>
                                <label className='form-label'>Вы выбрали</label>
                                <span className='label-required cursor-pointer' onClick={clearAll}>
                                    Удалить все
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {changedTooths.map((item) => (
                                    <div className='tooth-selected-block' key={item}>
                                        #{item}
                                        <img
                                            className='delete-detail-tooth align-top cursor-pointer'
                                            src='/images/icons/cross.svg'
                                            height={16}
                                            onClick={() => deleteTooth(item)}
                                        />
                                    </div>
                                ))}
                            </Col>
                        </Row>
                        <div className='mb-4'>
                            <div className='d-flex justify-content-between'>
                                <label htmlFor='changeDetail' className='form-label'>
                                    Комментарий
                                </label>
                                <span className='label-required fst-italic'>Обязательно</span>
                            </div>
                            <Form.Control
                                as='textarea'
                                rows={5}
                                placeholder='Напишите комментарий к работе'
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                className={errorDesc !== '' ? 'border-danger' : ''}
                            />
                            {errorDesc !== '' ? (
                                <div className='input-error-message text-danger'>{errorDesc}</div>
                            ) : null}
                        </div>
                        <Row>
                            <Col>
                                <Button variant='primary' onClick={saveHandle}>
                                    Сохранить
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={5}>
                        <div className='tooth-panel'>
                            <div className='top-numbers d-flex justify-content-evenly mb-3'>
                                <div className='tooth-numb' onClick={() => changeTooth(13)}>
                                    13
                                </div>
                                <div className='tooth-numb' onClick={() => changeTooth(12)}>
                                    12
                                </div>
                                <div className='tooth-numb' onClick={() => changeTooth(11)}>
                                    11
                                </div>
                                <div className='tooth-numb' onClick={() => changeTooth(21)}>
                                    21
                                </div>
                                <div className='tooth-numb' onClick={() => changeTooth(22)}>
                                    22
                                </div>
                                <div className='tooth-numb' onClick={() => changeTooth(23)}>
                                    23
                                </div>
                            </div>
                            <div className='mid-numbers d-flex justify-content-between mb-3'>
                                <div className='left-panel'>
                                    <div className='tooth-numb' onClick={() => changeTooth(14)}>
                                        14
                                    </div>
                                    <div className='tooth-numb' onClick={() => changeTooth(15)}>
                                        15
                                    </div>
                                    <div className='tooth-numb' onClick={() => changeTooth(16)}>
                                        16
                                    </div>
                                    <div className='tooth-numb' onClick={() => changeTooth(17)}>
                                        17
                                    </div>
                                    <div className='tooth-numb' onClick={() => changeTooth(18)}>
                                        18
                                    </div>
                                </div>
                                <div className='center-panel align-self-center'>
                                    <img src='/images/icons/tooth-up.svg' />
                                </div>
                                <div className='right-panel'>
                                    <div className='tooth-numb' onClick={() => changeTooth(24)}>
                                        24
                                    </div>
                                    <div className='tooth-numb' onClick={() => changeTooth(25)}>
                                        25
                                    </div>
                                    <div className='tooth-numb' onClick={() => changeTooth(26)}>
                                        26
                                    </div>
                                    <div className='tooth-numb' onClick={() => changeTooth(27)}>
                                        27
                                    </div>
                                    <div className='tooth-numb' onClick={() => changeTooth(28)}>
                                        28
                                    </div>
                                </div>
                            </div>
                            <div className='mid-numbers d-flex justify-content-between mb-3'>
                                <div className='left-panel'>
                                    <div className='tooth-numb' onClick={() => changeTooth(48)}>
                                        48
                                    </div>
                                    <div className='tooth-numb' onClick={() => changeTooth(47)}>
                                        47
                                    </div>
                                    <div className='tooth-numb' onClick={() => changeTooth(46)}>
                                        46
                                    </div>
                                    <div className='tooth-numb' onClick={() => changeTooth(45)}>
                                        45
                                    </div>
                                    <div className='tooth-numb' onClick={() => changeTooth(44)}>
                                        44
                                    </div>
                                </div>
                                <div className='center-panel align-self-center'>
                                    <img src='/images/icons/tooth-down.svg' />
                                </div>
                                <div className='right-panel'>
                                    <div className='tooth-numb' onClick={() => changeTooth(38)}>
                                        38
                                    </div>
                                    <div className='tooth-numb' onClick={() => changeTooth(37)}>
                                        37
                                    </div>
                                    <div className='tooth-numb' onClick={() => changeTooth(36)}>
                                        36
                                    </div>
                                    <div className='tooth-numb' onClick={() => changeTooth(35)}>
                                        35
                                    </div>
                                    <div className='tooth-numb' onClick={() => changeTooth(34)}>
                                        34
                                    </div>
                                </div>
                            </div>
                            <div className='bottom-numbers d-flex justify-content-evenly'>
                                <div className='tooth-numb' onClick={() => changeTooth(43)}>
                                    43
                                </div>
                                <div className='tooth-numb' onClick={() => changeTooth(42)}>
                                    42
                                </div>
                                <div className='tooth-numb' onClick={() => changeTooth(41)}>
                                    41
                                </div>
                                <div className='tooth-numb' onClick={() => changeTooth(31)}>
                                    31
                                </div>
                                <div className='tooth-numb' onClick={() => changeTooth(32)}>
                                    32
                                </div>
                                <div className='tooth-numb' onClick={() => changeTooth(33)}>
                                    33
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

export default AddDetailsModal;
