import { useContext, useEffect, useState } from 'react';
import OutsideClickHandler from '../../../components/OutsideClickHandler';
import { Accordion, AccordionContext, useAccordionButton } from 'react-bootstrap';

const sortType = [
    { id: 0, type: 'createdDate', direction: 0 },
    { id: 1, type: 'createdDate', direction: 1 },
    { id: 2, type: 'executionDate', direction: 0 },
    { id: 3, type: 'executionDate', direction: 1 },
    { id: 4, type: 'alphabet', direction: 0 },
    { id: 5, type: 'alphabet', direction: 1 },
    { id: 6, type: 'orderNumber', direction: 0 },
    { id: 7, type: 'orderNumber', direction: 1 },
];

const categories = [
    { id: 1, name: 'Хирургия' },
    { id: 2, name: 'Ортодонтия' },
    { id: 3, name: 'Ортопедия' },
];

const orderTypes = [
    { id: 1, name: 'Моделировка' },
    { id: 2, name: 'Производство' },
];

function CustomToggle({ name, eventKey }) {
    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(eventKey);

    const isCurrentEventKey = activeEventKey?.some((x) => x === eventKey);

    return (
        <div
            className={
                'plane-menu-item mb-1 d-flex justify-content-between align-items-center' +
                (isCurrentEventKey ? ' active-accordion' : null)
            }
            onClick={decoratedOnClick}
        >
            <span className='me-2'>{name}</span>
            <img src='/images/icons/chevron-right.svg' height={16} className='right-icon accordion-arrow' />
        </div>
    );
}

const BtnMenuCol = (props) => {
    const { changeState, changeSort, filterCategories, filterTypes } = props;

    const [menuIsOpen, setMenuIsOpen] = useState(false);

    // 0 - Главное, 1 - Сортировка, 2 - Фильтр
    const [menuType, setMenuType] = useState(0);

    const [sortSelected, setSortSelected] = useState(0);
    const [filterCateg, setFilterCateg] = useState([]);
    const [filterOrderTypes, setFilterOrderTypes] = useState([]);

    useEffect(() => {
        filterCategories(filterCateg);
    }, [filterCateg]);

    useEffect(() => {
        filterTypes(filterOrderTypes);
    }, [filterOrderTypes]);

    const changeSortType = (type) => {
        setSortSelected(type);
        changeSort(sortType.find((x) => x.id === type));
    };

    const changeFilterCateg = (categ) => {
        if (filterCateg.some((x) => x === categ)) {
            setFilterCateg(filterCateg.filter((x) => x !== categ));
        } else {
            setFilterCateg([...filterCateg, categ]);
        }
    };

    const changeFilterTypes = (tp) => {
        if (filterOrderTypes.some((x) => x === tp)) {
            setFilterOrderTypes(filterOrderTypes.filter((x) => x !== tp));
        } else {
            setFilterOrderTypes([...filterOrderTypes, tp]);
        }
    };

    const clearFilter = () => {
        setFilterCateg([]);
        setFilterOrderTypes([]);
    };

    const currentMenu = () => {
        let menu = null;
        switch (menuType) {
            case 0:
                menu = (
                    <>
                        <div className='plane-menu-item mb-1' onClick={changeState}>
                            <img src='/images/icons/eye-slash.svg' height={16} className='left-icon' />
                            <span>Скрыть колонку</span>
                        </div>
                        <div
                            className='plane-menu-item mb-1 d-flex justify-content-between align-items-center'
                            onClick={() => setMenuType(1)}
                        >
                            <span>Сортировка</span>
                            <img src='/images/icons/chevron-right.svg' height={16} className='right-icon' />
                        </div>
                        <div
                            className='plane-menu-item mb-1 d-flex justify-content-between align-items-center'
                            onClick={() => setMenuType(2)}
                        >
                            <span>Фильтр</span>
                            <img src='/images/icons/chevron-right.svg' height={16} className='right-icon' />
                        </div>
                    </>
                );
                break;
            case 1:
                menu = (
                    <>
                        <div className='plane-menu-item mb-1 active d-flex align-items-center'>
                            <button className='btn menu-arrow-left' onClick={() => setMenuType(0)}>
                                <img src='/images/icons/arrow-left.svg' height={16} />
                            </button>
                            <span>Сортировка</span>
                        </div>
                        <Accordion alwaysOpen>
                            <div>
                                <CustomToggle eventKey='0' name='По дате создания' />
                                <Accordion.Collapse eventKey='0'>
                                    <>
                                        <div
                                            className={
                                                'plane-menu-item mb-1 d-flex justify-content-between align-items-center' +
                                                (sortSelected === 0 ? ' selected' : '')
                                            }
                                            onClick={() => changeSortType(0)}
                                        >
                                            <span>сначала ранние</span>
                                            {sortSelected === 0 ? (
                                                <img src='/images/icons/vector.svg' height={7} />
                                            ) : null}
                                        </div>
                                        <div
                                            className={
                                                'plane-menu-item mb-1 d-flex justify-content-between align-items-center' +
                                                (sortSelected === 1 ? ' selected' : '')
                                            }
                                            onClick={() => changeSortType(1)}
                                        >
                                            <span>сначала поздние</span>
                                            {sortSelected === 1 ? (
                                                <img src='/images/icons/vector.svg' height={7} />
                                            ) : null}
                                        </div>
                                    </>
                                </Accordion.Collapse>
                                <CustomToggle eventKey='1' name='По дате исполнения' />
                                <Accordion.Collapse eventKey='1'>
                                    <>
                                        <div
                                            className={
                                                'plane-menu-item mb-1 d-flex justify-content-between align-items-center' +
                                                (sortSelected === 2 ? ' selected' : '')
                                            }
                                            onClick={() => changeSortType(2)}
                                        >
                                            <span>сначала ранние</span>
                                            {sortSelected === 2 ? (
                                                <img src='/images/icons/vector.svg' height={7} />
                                            ) : null}
                                        </div>
                                        <div
                                            className={
                                                'plane-menu-item mb-1 d-flex justify-content-between align-items-center' +
                                                (sortSelected === 3 ? ' selected' : '')
                                            }
                                            onClick={() => changeSortType(3)}
                                        >
                                            <span>сначала поздние</span>
                                            {sortSelected === 3 ? (
                                                <img src='/images/icons/vector.svg' height={7} />
                                            ) : null}
                                        </div>
                                    </>
                                </Accordion.Collapse>
                                <CustomToggle eventKey='2' name='По имени пациента' />
                                <Accordion.Collapse eventKey='2'>
                                    <>
                                        <div
                                            className={
                                                'plane-menu-item mb-1 d-flex justify-content-between align-items-center' +
                                                (sortSelected === 4 ? ' selected' : '')
                                            }
                                            onClick={() => changeSortType(4)}
                                        >
                                            <span>от А-Я</span>
                                            {sortSelected === 4 ? (
                                                <img src='/images/icons/vector.svg' height={7} />
                                            ) : null}
                                        </div>
                                        <div
                                            className={
                                                'plane-menu-item mb-1 d-flex justify-content-between align-items-center' +
                                                (sortSelected === 5 ? ' selected' : '')
                                            }
                                            onClick={() => changeSortType(5)}
                                        >
                                            <span>от Я-А</span>
                                            {sortSelected === 5 ? (
                                                <img src='/images/icons/vector.svg' height={7} />
                                            ) : null}
                                        </div>
                                    </>
                                </Accordion.Collapse>
                                <CustomToggle eventKey='3' name='По номеру заказа' />
                                <Accordion.Collapse eventKey='3'>
                                    <>
                                        <div
                                            className={
                                                'plane-menu-item mb-1 d-flex justify-content-between align-items-center' +
                                                (sortSelected === 7 ? ' selected' : '')
                                            }
                                            onClick={() => changeSortType(7)}
                                        >
                                            <span>начиная с большего</span>
                                            {sortSelected === 7 ? (
                                                <img src='/images/icons/vector.svg' height={7} />
                                            ) : null}
                                        </div>
                                        <div
                                            className={
                                                'plane-menu-item mb-1 d-flex justify-content-between align-items-center' +
                                                (sortSelected === 6 ? ' selected' : '')
                                            }
                                            onClick={() => changeSortType(6)}
                                        >
                                            <span>начиная с меньшего</span>
                                            {sortSelected === 6 ? (
                                                <img src='/images/icons/vector.svg' height={7} />
                                            ) : null}
                                        </div>
                                    </>
                                </Accordion.Collapse>
                            </div>
                        </Accordion>
                    </>
                );
                break;
            case 2:
                menu = (
                    <>
                        <div className='d-flex mb-1'>
                            <div className='plane-menu-item active d-flex align-items-center me-1 pe-5'>
                                <button className='btn menu-arrow-left' onClick={() => setMenuType(0)}>
                                    <img src='/images/icons/arrow-left.svg' height={16} />
                                </button>
                                <span>Фильтр</span>
                            </div>
                            <button className='btn btn-filter-reset' onClick={clearFilter}>
                                Сбросить
                            </button>
                        </div>
                        <Accordion alwaysOpen>
                            <div>
                                <CustomToggle eventKey='0' name='Категория' />
                                <Accordion.Collapse eventKey='0'>
                                    <>
                                        {categories.map((item) => (
                                            <div
                                                key={item.id}
                                                className='plane-menu-item mb-1 d-flex justify-content-between align-items-center'
                                                onClick={() => changeFilterCateg(item.id)}
                                            >
                                                <div className='form-check d-flex align-items-center'>
                                                    <input
                                                        className='form-check-input mt-0 me-2'
                                                        type='checkbox'
                                                        value={item.id}
                                                        id={'categoryCheck_' + item.id}
                                                        checked={filterCateg.some((x) => x === item.id)}
                                                        readOnly
                                                    />
                                                    <label className='form-check-label cursor-pointer'>
                                                        {item.name}
                                                    </label>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                </Accordion.Collapse>
                                <CustomToggle eventKey='1' name='Тип работ' />
                                <Accordion.Collapse eventKey='1'>
                                    <>
                                        {orderTypes.map((item) => (
                                            <div
                                                key={item.id}
                                                className='plane-menu-item mb-1 d-flex justify-content-between align-items-center'
                                                onClick={() => changeFilterTypes(item.name)}
                                            >
                                                <div className='form-check d-flex align-items-center'>
                                                    <input
                                                        className='form-check-input mt-0 me-2'
                                                        type='checkbox'
                                                        value={item.name}
                                                        id={'categoryCheck_' + item.name}
                                                        checked={filterOrderTypes.some((x) => x === item.name)}
                                                        readOnly
                                                    />
                                                    <label className='form-check-label cursor-pointer'>
                                                        {item.name}
                                                    </label>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                </Accordion.Collapse>
                            </div>
                        </Accordion>
                    </>
                );
                break;
            default:
                break;
        }
        return menu;
    };

    return (
        <div className='position-relative btn-menu-col'>
            <button className='btn btn-icon-menu' onClick={() => setMenuIsOpen(true)}>
                <img src='/images/icons/dots-vertical.svg' width={16} height={16} />
            </button>
            <OutsideClickHandler
                callback={() => setMenuIsOpen(false)}
                classes={'plane-menu-panel ' + (menuIsOpen == true ? 'd-block' : 'd-none')}
            >
                {currentMenu()}
            </OutsideClickHandler>
        </div>
    );
};

export default BtnMenuCol;
