import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useGetMyWorkzonesQuery } from '../../../app/api/workzoneApiSlice';
import WorkzoneCreateModal from './WorkzoneCreateModal';
import { Link } from 'react-router-dom';
import { selectUserId } from '../../../app/authSlice';
import { useSelector } from 'react-redux';

const WorkzonePage = () => {
    const { data, loading } = useGetMyWorkzonesQuery();
    const userId = useSelector(selectUserId);

    const [currentZoneType, setCurrentZoneType] = useState(0);

    return (
        <div className='card-simple'>
            <Row className='mb-3'>
                <Col md={8}>
                    <h3 className='text-left mb-4'>Рабочие зоны</h3>
                </Col>
                <Col className='text-end'>
                    <WorkzoneCreateModal />
                </Col>
            </Row>
            <Row className='mb-4'>
                <Col>
                    <button
                        className={
                            'btn btn-sm-dental btn-outline-secondary me-3' +
                            (currentZoneType == 0 ? ' active' : '')
                        }
                        onClick={() => setCurrentZoneType(0)}
                    >
                        Созданные рабочие зоны
                    </button>
                    <button
                        className={
                            'btn btn-sm-dental btn-outline-secondary me-3' +
                            (currentZoneType == 1 ? ' active' : '')
                        }
                        onClick={() => setCurrentZoneType(1)}
                    >
                        Рабочие зоны, где я участник
                    </button>
                </Col>
            </Row>
            <hr />
            <Row>
                <Col>
                    {data
                        ?.filter((x) =>
                            currentZoneType === 0
                                ? x.personId === userId
                                : x.personId !== userId
                        )
                        ?.map((item) => (
                            <div key={item.id}>
                                <Link
                                    className='btn btn-link'
                                    to={'/workzone/' + item.id + '/plane'}
                                >
                                    {item.name}
                                </Link>
                            </div>
                        ))}
                </Col>
            </Row>
        </div>
    );
};

export default WorkzonePage;
