import { apiSlice } from './apiSlice';

export const profileApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAuthProfile: builder.query({
            query: () => 'api/persons/GetAuthInfo',
            providesTags: (result) => [{ type: 'Profile', id: result?.email }],
        }),
        getMyself: builder.query({
            query: () => 'api/persons/GetMyself',
            providesTags: (result) => [{ type: 'Profile', id: result?.email }],
        }),
    }),
});

export const {
    useGetAuthProfileQuery,
    useLazyGetAuthProfileQuery,
    useGetMyselfQuery,
} = profileApiSlice;
