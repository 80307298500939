import { Modal } from 'react-bootstrap';

const AfterCreateOrderModal = (props) => {
    const { isOpen, closeModal } = props;

    const handleClose = () => closeModal();

    return (
        <Modal show={isOpen} onHide={handleClose} size='md' className='modal-delete' centered>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body className='text-center mb-5'>
                <img src='/images/icons/archive.svg' height={40} className='align-middle modal-icon' /> Заказ сохранен в
                черновиках
            </Modal.Body>
        </Modal>
    );
};

export default AfterCreateOrderModal;
