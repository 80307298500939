import { useState } from 'react';
import { DateToString } from '../../../helpers/DateHelpers';
import DeleteOrderModal from '../orders/DeteleOrderModal';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useAddOrderPinMutation, useRemoveOrderPinMutation } from '../../../app/api/orderApiSlice';
import { useNavigate } from 'react-router-dom';

const categoryColors = [
    { id: 1, cls: 'order-category-1' },
    { id: 2, cls: 'order-category-2' },
    { id: 3, cls: 'order-category-3' },
];

const periodShort = [
    { id: 0, name: '24 часа' },
    { id: 1, name: '3 дня' },
    { id: 2, name: '10 дней' },
];

const OrderCard = (props) => {
    const { order, deletedOrder } = props;

    const navigate = useNavigate();

    const [addPin] = useAddOrderPinMutation();
    const [removePin] = useRemoveOrderPinMutation();

    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

    const handleDeleteOrder = () => {
        setDeleteModalIsOpen(false);
        deletedOrder(order.id);
    };

    const handleClickPin = (e) => {
        e.stopPropagation();
        if (order.isPinned) {
            removePin(order.id);
        } else {
            addPin(order.id);
        }
    };

    const handleClickOrder = () => {
        navigate(`orders/${order.id}`);
    };

    const handleClickDelete = (e) => {
        e.stopPropagation();
        setDeleteModalIsOpen(true);
    };

    return (
        <>
            <DeleteOrderModal
                isOpen={deleteModalIsOpen}
                closeModal={() => setDeleteModalIsOpen(false)}
                orderId={order.id}
                deletedOrder={handleDeleteOrder}
            />
            <div className='card-order' onClick={handleClickOrder}>
                <div className='card-order-header d-flex justify-content-between mb-1'>
                    <div
                        style={{
                            marginTop: -5,
                        }}
                    >
                        <div className='order-header-type-block d-inline-block me-2'>
                            <div
                                className={
                                    'd-inline-block order-category ' +
                                    categoryColors.find((x) => x.id === order.orderType.orderCategoryId).cls
                                }
                            >
                                {order.orderType?.orderCategory?.name}
                            </div>
                            <div className='d-inline-block order-type'>{order.orderType?.shortName}</div>
                        </div>
                        <div className='d-inline-block order-identificator'>#{order.id}</div>
                    </div>
                    <div className='header-icons'>
                        <OverlayTrigger
                            placement='top'
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <Tooltip className='dental-tooltip' id={'delete-tooltip-' + order.id}>
                                    Закрепить
                                </Tooltip>
                            }
                        >
                            <button
                                className={'btn me-3 icon-header' + (order.isPinned ? ' pinned' : '')}
                                onClick={handleClickPin}
                            >
                                <img
                                    src={'/images/icons/' + (order.isPinned ? 'thumbtack-fill.svg' : 'thumbtack.svg')}
                                    width={16}
                                    height={16}
                                />
                            </button>
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement='top'
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <Tooltip className='dental-tooltip' id={'delete-tooltip-' + order.id}>
                                    Удалить
                                </Tooltip>
                            }
                        >
                            <button className='btn icon-header' onClick={handleClickDelete}>
                                <img src='/images/icons/trash.svg' width={16} height={16} />
                            </button>
                        </OverlayTrigger>
                    </div>
                </div>
                <div className='card-order-body pt-0'>
                    <div className='order-full-type'>{order.orderType.name}</div>
                    <div className='order-patient-name'>{order?.patient?.lastName}</div>
                    <div className='d-flex'>
                        <div className='flex-grow-1'>
                            <div className='order-label'>Заявка создана</div>
                            <div className='order-value'>{DateToString(new Date(order.createdAt))}</div>
                        </div>
                        <div>
                            <div className='order-label'>Срок выполнения</div>
                            <div className='order-value'>
                                {periodShort.find((x) => x.id === order.executionPeriod).name}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OrderCard;
