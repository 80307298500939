import { Modal } from 'react-bootstrap';

const AfterDeleteOrderModal = (props) => {
    const { orderId, isOpen, closeModal } = props;

    const handleClose = () => closeModal();

    return (
        <Modal show={isOpen} onHide={handleClose} size='lg' className='modal-delete' centered>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body className='text-center'>
                <img src='/images/icons/garbage.svg' height={40} /> Заказ #{orderId} удалён
                <p className='text-modal'>
                    В течение месяца он будет храниться в папке "Удалённые". Вы можете восстановить его до истечения
                    срока.
                </p>
            </Modal.Body>
        </Modal>
    );
};

export default AfterDeleteOrderModal;
