import { useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useCreatePatientMutation } from '../../../app/api/patientApiSlice';

const AddPatientModal = (props) => {
    const { isOpen, closeModal, workzoneId } = props;

    const [createPatient] = useCreatePatientMutation();

    const [lastName, setLastName] = useState('');
    const [yearOfBirth, setYearOfBirth] = useState('');
    const [gender, setGender] = useState(1);

    const handleClose = () => closeModal(0);

    const addPatient = async () => {
        const createdPatient = await createPatient({
            lastName,
            yearOfBirth,
            gender,
            workzoneId,
        });
        setLastName('');
        setYearOfBirth('');
        setGender(1);
        closeModal(createdPatient.data.id);
    };

    return (
        <Modal show={isOpen} onHide={handleClose} backdrop='static' size='md' className='add-details-modal' centered>
            <Modal.Header closeButton>
                <Modal.Title>Создание нового пациента</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className='mb-3'>
                    <Col>
                        <Form.Label className='mb-2'>Фамилия</Form.Label>
                        <Form.Control type='text' value={lastName} onChange={(e) => setLastName(e.target.value)} />
                    </Col>
                </Row>
                <Row className='mb-3'>
                    <Col>
                        <Form.Label className='mb-2'>Год рождения</Form.Label>
                        <Form.Control
                            type='number'
                            value={yearOfBirth}
                            onChange={(e) => setYearOfBirth(e.target.value)}
                            className='w-25'
                        />
                    </Col>
                </Row>
                <Row className='mb-3'>
                    <Col>
                        <Form.Label className='mb-2'>Пол</Form.Label>
                        <div>
                            <Form.Check
                                inline
                                label='Мужской'
                                name='groupGender'
                                type='radio'
                                id='inline-radio-gender-1'
                                value={1}
                                onChange={(e) => setGender(e.target.value)}
                                checked={parseInt(gender) === 1}
                            />
                            <Form.Check
                                inline
                                label='Женский'
                                name='groupGender'
                                type='radio'
                                id='inline-radio-gender-0'
                                value={0}
                                onChange={(e) => setGender(e.target.value)}
                                checked={parseInt(gender) === 0}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button variant='primary' onClick={addPatient}>
                            Сохранить
                        </Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

export default AddPatientModal;
