import { Col, Row } from 'react-bootstrap';
import { useGetMyWorkzonesQuery } from '../../../app/api/workzoneApiSlice';
import { Link } from 'react-router-dom';

const WorkzonesPanel = (props) => {
    const { currentWorkzone } = props;
    const { data } = useGetMyWorkzonesQuery();

    return (
        <Row>
            <Col>
                <div className='workzones-list'>
                    {data?.map((item) => (
                        <div
                            className='d-inline-block workzone-block'
                            key={item.id}
                        >
                            <Link
                                to={`/workzone/${item.id}/plane`}
                                className={
                                    item.id === parseInt(currentWorkzone)
                                        ? 'active'
                                        : ''
                                }
                            >
                                {item.name}
                            </Link>
                        </div>
                    ))}
                </div>
            </Col>
        </Row>
    );
};

export default WorkzonesPanel;
