import React from 'react';
import { ThemeContext, themes } from '../../contexts/ThemeContext';

const ThemeSwitcher = () => (
    <ThemeContext.Consumer>
        {({ theme, setTheme }) => (
            <li className='nav-item theme-switcher'>
                <img
                    src='/images/icons/sun.svg'
                    height='24'
                    className='me-2 switcher-sun'
                />
                <div className='form-check form-switch'>
                    <input
                        className='form-check-input'
                        type='checkbox'
                        id='flexSwitchCheckChecked'
                        checked={theme === themes.dark}
                        onChange={() =>
                            theme === themes.dark
                                ? setTheme(themes.light)
                                : setTheme(themes.dark)
                        }
                    />
                </div>
                <img
                    src='/images/icons/moon.svg'
                    height='24'
                    className='switcher-moon'
                />
            </li>
        )}
    </ThemeContext.Consumer>
);

export default ThemeSwitcher;
