import { Col, Row } from 'react-bootstrap';
import { useGetMyselfQuery } from '../../../app/api/profileApiSlice';

const ProfilePage = () => {
    const { data, isLoading } = useGetMyselfQuery();
    console.log('profileDta', data);
    return (
        <div className='card-simple'>
            {isLoading ? (
                <span>Загрузка...</span>
            ) : (
                <Row>
                    <Col md={2}>
                        <img
                            src='/images/icons/person.png'
                            className='img-fluid'
                        />
                    </Col>
                    <Col md={7}>
                        <h3 className='text-left mb-4'>{data.fio}</h3>
                        <div className='block-label-value'>
                            <span className='label'>Дата рождения</span>
                            <span className='value'>{data.dateOfBirth}</span>
                        </div>
                        <div className='block-label-value'>
                            <span className='label'>Пол</span>
                            <span className='value'>
                                {data.gender ? 'мужской' : 'женский'}
                            </span>
                        </div>
                        <div className='block-label-value'>
                            <span className='label'>Электронная почта</span>
                            <span className='value'>{data.email}</span>
                        </div>
                    </Col>
                    <Col>
                        <div className='raiting-header'>Ваш рейтинг: * 4,8</div>
                        <div>на основании 159 оценок</div>
                    </Col>
                </Row>
            )}
        </div>
    );
};

export default ProfilePage;
