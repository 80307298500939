import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import { useEffect, useState } from 'react';
import { getUser, logout, renewToken } from './services/AuthService';
import OAuthCallback from './pages/oauth-callback';
import LayoutMaster from './components/layout/LayoutMaster';
import { useDispatch } from 'react-redux';
import { setIsAuthorize, setUserId } from './app/authSlice';
import IndexPage from './pages/IndexPage';
import LayoutProfile from './components/layout/LayoutProfile';
import ProfilePage from './pages/lk/profile/ProfilePage';
import WorkzonePage from './pages/lk/workzone/WorkzonePage';
import { useLazyGetAuthProfileQuery } from './app/api/profileApiSlice';
import PlanePage from './pages/workzone/PlanePage/PlanePage';
import CreateOrderModal from './pages/workzone/orders/CreateOrderModal';

function App() {
    const dispatch = useDispatch();

    const [getAuthInfo, { data, isLoading }] = useLazyGetAuthProfileQuery();

    const [authenticated, setAuthenticated] = useState(false);
    const [user, setUser] = useState('');
    const [rendering, setRendering] = useState(true);

    useEffect(() => {
        async function fetchData() {
            const user = await getUser();
            const accessToken = user?.access_token;

            setAuthenticated(!!accessToken);
            setUser(user);

            if (!!accessToken) {
                dispatch(setIsAuthorize(true));
                const profile = await getAuthInfo().unwrap();
                dispatch(setUserId(profile.id));
            }

            setRendering(false);
        }

        fetchData();
    }, [authenticated]);

    if (rendering) {
        return <>Загрузка...</>;
    }

    console.log('data', data);

    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path={'/'}
                    element={
                        <LayoutMaster>
                            <IndexPage />
                        </LayoutMaster>
                    }
                />
                <Route
                    path={'/example'}
                    element={
                        <LayoutMaster>
                            <div>This is test page</div>
                        </LayoutMaster>
                    }
                />
                <Route
                    path={'/resources'}
                    element={
                        <ProtectedRoute
                            authenticated={authenticated}
                            redirectPath='/'
                        >
                            <LayoutMaster>
                                <div>
                                    Authenticated OAuth Server result:{' '}
                                    {JSON.stringify(user)}
                                </div>

                                <br></br>

                                <div>
                                    {isLoading
                                        ? 'Загрузка'
                                        : `Resource got with access token: ${data?.user}`}
                                </div>

                                <button onClick={logout}>Log out</button>
                                <button onClick={renewToken}>
                                    GetAccToken
                                </button>
                            </LayoutMaster>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={'/lk/profile'}
                    element={
                        <ProtectedRoute
                            authenticated={authenticated}
                            redirectPath='/'
                        >
                            <LayoutProfile>
                                <ProfilePage />
                            </LayoutProfile>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={'/lk/workzone'}
                    element={
                        <ProtectedRoute
                            authenticated={authenticated}
                            redirectPath='/'
                        >
                            <LayoutProfile>
                                <WorkzonePage />
                            </LayoutProfile>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={'/workzone/:workzoneId/plane/*'}
                    element={
                        <ProtectedRoute
                            authenticated={authenticated}
                            redirectPath='/'
                        >
                            <LayoutMaster>
                                <PlanePage />
                            </LayoutMaster>
                        </ProtectedRoute>
                    }
                >
                    <Route
                        path='orders/:orderId'
                        element={<CreateOrderModal />}
                    />
                </Route>
                <Route path='/oauth/callback' element={<OAuthCallback />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
