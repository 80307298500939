import { useGetOrderFilesQuery, useGetOrderFileTypesQuery } from '../../../app/api/orderApiSlice';
import AccordionKT from './AccordionBlocks/AccordionKT';
import AccordionMrt from './AccordionBlocks/AccordionMrt';
import AccordionOther from './AccordionBlocks/AccordionOther';
import AccordionPhoto from './AccordionBlocks/AccordionPhoto';
import AccordionScans from './AccordionBlocks/AccordionScans';

const OrderFiles = (props) => {
    const { orderId } = props;

    const { data: fileTypes } = useGetOrderFileTypesQuery();
    const { data: files } = useGetOrderFilesQuery(orderId);

    console.log('files', files);

    return (
        <div>
            <AccordionScans
                files={files?.filter((x) => x.type.categoryId === 1)}
                fileTypes={fileTypes?.filter((x) => x.categoryId === 1)}
                orderId={orderId}
            />
            <AccordionKT files={files?.filter((x) => x.type.categoryId === 2)} orderId={orderId} />
            <AccordionMrt files={files?.filter((x) => x.type.categoryId === 3)} orderId={orderId} />
            <AccordionPhoto
                files={files?.filter((x) => x.type.categoryId === 4)}
                fileTypes={fileTypes?.filter((x) => x.categoryId === 4)}
                orderId={orderId}
            />
            <AccordionOther files={files?.filter((x) => x.type.categoryId === 5)} orderId={orderId} />
        </div>
    );
};

export default OrderFiles;
