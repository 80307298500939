import { apiSlice } from './apiSlice';

export const workzoneApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getMyWorkzones: builder.query({
            query: () => 'api/workzones',
            providesTags: () => [{ type: 'Workzones', id: 'List' }],
        }),
        getWorkzoneById: builder.query({
            query: (id) => 'api/workzones/' + id,
            providesTags: () => [{ type: 'Workzones', id: 'List' }],
        }),
        getMyFavoriteWorkzones: builder.query({
            query: (id) => 'api/workzones/getmyfavorites',
            providesTags: () => [{ type: 'Workzones', id: 'List' }],
        }),
        createWorkzone: builder.mutation({
            query: (data) => ({
                url: 'api/workzones',
                method: 'POST',
                body: { ...data },
            }),
            invalidatesTags: [{ type: 'Workzones', id: 'List' }],
        }),
    }),
});

export const {
    useGetMyWorkzonesQuery,
    useCreateWorkzoneMutation,
    useGetWorkzoneByIdQuery,
    useGetMyFavoriteWorkzonesQuery,
} = workzoneApiSlice;
