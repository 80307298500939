import { Col, Form, Row } from 'react-bootstrap';
import OrderFilesAccordion from '../OrderFilesAccordion';
import { useState } from 'react';
import OrderFileDownloader from '../OrderFileDownloader';
import { useDeleteDocMutation, useDownloadDocMutation } from '../../../../app/api/orderApiSlice';

const AccordionMrt = (props) => {
    const { files, orderId } = props;

    const [downloadFile] = useDownloadDocMutation();
    const [deleteDoc] = useDeleteDocMutation();

    const downloadScan = async (fileId) => {
        const file = await downloadFile(fileId);
        const a = document.createElement('a');
        a.href = file.data.url;
        a.download = files.find((x) => x.id === fileId).fileName;
        a.click();
        URL.revokeObjectURL(file.data.url);
    };

    const deleteFile = async (fileId) => {
        await deleteDoc(fileId);
    };

    return (
        <OrderFilesAccordion title='МРТ'>
            <OrderFileDownloader
                infoText='Файлы загружать архивом: .zip, .rar. А так же файлы с расширением .dcm, .dicom. Максимальный размер файла 500 МБ.'
                fileType={5}
                orderId={orderId}
                accept={{ 'application/zip': ['.zip', '.rar', '.dcm', '.dicom'] }}
                maxSize={500 * 1024 * 1024}
            />
            {files?.length > 0 && (
                <div className='order-files-block mb-3'>
                    <div className='order-files-title mb-2'>Прикреплённые файлы</div>
                    <div>
                        {files.map((item) => (
                            <div key={item.id} className='mb-2 d-flex justify-content-between align-items-center'>
                                <div className='order-file'>{item.fileName}</div>
                                <div>
                                    <button className='btn order-file-action-btn' onClick={() => downloadScan(item.id)}>
                                        Скачать
                                    </button>
                                    <button className='btn order-file-action-btn' onClick={() => deleteFile(item.id)}>
                                        Удалить
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </OrderFilesAccordion>
    );
};

export default AccordionMrt;
