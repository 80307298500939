import { Button, Modal } from 'react-bootstrap';
import { useDeleteOrderMutation } from '../../../app/api/orderApiSlice';

const DeleteOrderModal = (props) => {
    const { orderId, isOpen, closeModal, deletedOrder } = props;

    const [deleteOrder] = useDeleteOrderMutation();

    const handleClose = () => closeModal();

    const handleDelete = async () => {
        await deleteOrder(orderId);
        deletedOrder();
    };

    return (
        <Modal show={isOpen} onHide={handleClose} backdrop='static' size='md' className='modal-delete' centered>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body className='text-center'>Удалить заказ #{orderId}</Modal.Body>
            <Modal.Footer className='justify-content-center'>
                <Button variant='primary' onClick={handleClose} className='m-0 me-4'>
                    Не удалять
                </Button>
                <Button variant='outline-primary' onClick={handleDelete} className='m-0'>
                    Да, удалить
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteOrderModal;
