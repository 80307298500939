import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
    name: 'auth',
    initialState: { isAuthorize: false, userId: 0 },
    reducers: {
        setIsAuthorize: (state, action) => {
            state.isAuthorize = action.payload;
        },
        setUserId: (state, action) => {
            state.userId = action.payload;
        },
        logOut: (state, action) => {
            localStorage.clear();
            state.isAuthorize = false;
        },
    },
});

export const { logOut, setIsAuthorize, setUserId } = authSlice.actions;

export default authSlice.reducer;

export const selectIsAuthorize = (state) => state.auth.isAuthorize;
export const selectUserId = (state) => state.auth.userId;
