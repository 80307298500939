import { UserManager, WebStorageStateStore } from 'oidc-client-ts';
import { AUTH_SERVER_URL, CURRENT_SERVER_URL } from './UrlConstants';

let userManager = new UserManager({
    authority: AUTH_SERVER_URL,
    client_id: 'react-client',
    client_secret: '901564A5-E7FE-42CB-B10D-61EF6A8F3654',
    redirect_uri: `${CURRENT_SERVER_URL}/oauth/callback`,
    silent_redirect_uri: `${CURRENT_SERVER_URL}/oauth/callback`,
    post_logout_redirect_uri: `${CURRENT_SERVER_URL}/`,
    revokeTokenTypes: ['refresh_token'],
    automaticSilentRenew: true,
    response_type: 'code',
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    // this is for getting user.profile data, when open id connect is implemented
    //scope: 'api1 openid profile'
    // this is just for OAuth2 flow
    scope: 'api1 openid profile offline_access',
});

export async function isAuthenticated() {
    let token = await getAccessToken();

    if (!token) return false;

    return true;
}

export async function handleOAuthCallback(callbackUrl) {
    console.log('This is redirect callback');
    try {
        const user = await userManager.signinRedirectCallback(callbackUrl);
        return user;
    } catch (e) {
        alert(e);
        console.log(`error while handling oauth callback: ${e}`);
    }
}

export async function handleAccessTokenExpiring(callbackUrl) {
    userManager.events.addAccessTokenExpiring(async () => {
        console.log('Try to renew token');
        await renewToken();
    });
}

export async function sendOAuthRequest() {
    await userManager.signinRedirect();
}

// renews token using refresh token
export async function renewToken() {
    console.log('Try to renew token');
    try {
        const user = await userManager.signinSilent();
        return user;
    } catch (error) {
        localStorage.clear();
    }
}

export async function getAccessToken() {
    console.log('i get acc token');
    const user = await userManager.getUser();
    return user?.access_token;
}

export async function getUser() {
    const user = await userManager.getUser();
    return user;
}

export async function logout() {
    await userManager.clearStaleState();
    await userManager.signoutRedirect();
}

// This function is used to access token claims
// `.profile` is available in Open Id Connect implementations
// in simple OAuth2 it is empty, because UserInfo endpoint does not exist
// export async function getRole() {
//     const user = await userManager.getUser();
//     return user?.profile?.role;
// }

// This function is used to change account similar way it is done in Google
// export async function selectOrganization() {
//     const args = {
//         prompt: "select_account"
//     }
//     await userManager.signinRedirect(args);
// }
