import { useSelector } from 'react-redux';
import { selectIsAuthorize } from '../../app/authSlice';
import { logout, sendOAuthRequest } from '../../services/AuthService';
import { NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const MenuProfile = (props) => {
    const isAuthorize = useSelector(selectIsAuthorize);
    return (
        <>
            {isAuthorize ? (
                <>
                    <li className='nav-item me-2'>
                        <button className='btn btn-outline-secondary btn-icon'>
                            <img
                                src='/images/icons/notification.png'
                                height='16'
                            />
                        </button>
                    </li>
                    <NavDropdown
                        title={
                            <img src='/images/icons/person.png' height='40' />
                        }
                        className='dropdown-profile-icon'
                    >
                        <NavDropdown.Item
                            as={Link}
                            to='/lk/profile'
                            className='position-relative'
                        >
                            Личный кабинет
                        </NavDropdown.Item>
                        <NavDropdown.Item
                            className='position-relative'
                            onClick={logout}
                        >
                            Выйти
                        </NavDropdown.Item>
                    </NavDropdown>
                </>
            ) : (
                <li className='nav-item'>
                    <button
                        className='nav-link text-dark btn btn-outline-secondary'
                        onClick={async (_) => {
                            await sendOAuthRequest();
                        }}
                    >
                        Войти/Зарегистрироваться
                    </button>
                </li>
            )}
        </>
    );
};

export default MenuProfile;
