import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import {
    useCreateDetailMutation,
    useCreateOrderMutation,
    useDeleteDetailMutation,
    useGetOrderCategoriesQuery,
    useLazyGetOrderByIdQuery,
    useUpdateDetailMutation,
    useUpdateOrderMutation,
    useUploadDocMutation,
} from '../../../app/api/orderApiSlice';
import { useEffect, useState } from 'react';
import { useGetMyFavoriteWorkzonesQuery, useGetMyWorkzonesQuery } from '../../../app/api/workzoneApiSlice';
import AddDetailsModal from './AddDetailsModal';
import DropdownInput from './PatientDropdownInput';
import DetailsPanel from './DetailsPanel';
import DeleteDetailsModal from './DeteleDetailsModal';
import AfterCreateOrderModal from './AfterCreateOrderModal';
import ChangeSaveStatusOrderModal from './ChangeSaveStatusOrderModal';
import OrderFiles from './OrderFiles';

const labelMb = 4;
const inputMb = 12 - labelMb;

const CreateOrderModal = () => {
    const params = useParams();
    const navigate = useNavigate();

    const { data } = useGetOrderCategoriesQuery();
    const { data: favorites } = useGetMyFavoriteWorkzonesQuery();
    const { data: myWorkzones, workzonesLoading } = useGetMyWorkzonesQuery();
    const [getOrderById] = useLazyGetOrderByIdQuery();
    const [createOrder] = useCreateOrderMutation();
    const [updateOrder] = useUpdateOrderMutation();
    const [uploadFile] = useUploadDocMutation();
    const [createDetail] = useCreateDetailMutation();
    const [updateDetail] = useUpdateDetailMutation();
    const [deleteDetail] = useDeleteDetailMutation();

    const [categoryId, setCategoryId] = useState();
    const [typeId, setTypeId] = useState();
    const [executorWorkzoneId, setExecutorWorkzoneId] = useState(0);
    const [workzoneId, setWorkzoneId] = useState();
    const [quantity, setQuantity] = useState(1);
    const [executionPeriod, setExecutionPeriod] = useState(2);
    const [cost, setCost] = useState(0);
    const [description, setDescription] = useState('');

    const [patientId, setPatientId] = useState(0);
    const [mrtFile, setMrtFile] = useState(null);

    const [isOpenDetailsModal, setIsOpenDetailsModal] = useState(false);
    const [changedTeeth, setChangedTeeth] = useState([]);
    const [changedDetailIndex, setChangedDetailIndex] = useState(-1);
    const [changedComments, setChangedComments] = useState('');
    const [needZIndex, setNeedZIndex] = useState(false);

    const [orderDetails, setOrderDetails] = useState([]);

    const [isOpenDeleteDetailsModal, setIsOpenDeleteDetailsModal] = useState(false);
    const [indexDeleteDetails, setIndexDeleteDetails] = useState(-1);

    const [isCreatedOpenModal, setIsCreatedOpenModal] = useState(false);
    const [isOpenChangeStateModal, setIsOpenChangeStateModal] = useState(false);

    useEffect(() => {
        async function loadData() {
            if (params.orderId > 0) {
                const result = await getOrderById(params.orderId);
                const order = result.data;
                console.log('order', order);
                setWorkzoneId(order.workzoneId);
                setCategoryId(order.orderType.orderCategoryId);
                setTypeId(order.orderTypeId);
                setExecutorWorkzoneId(order.executorWorkzoneId ?? 0);
                setQuantity(order.quantity);
                setExecutionPeriod(order.executionPeriod);
                setCost(order.cost);
                setDescription(order.description);
                setPatientId(order.patientId);
                setOrderDetails(
                    order.orderDetails.map((x) => {
                        return { ...x, teeth: JSON.parse(x?.teeth) };
                    })
                );
            }
        }
        loadData();
    }, [params.orderId]);

    useEffect(() => {
        if (data?.length > 0 && categoryId === undefined) {
            setCategoryId(data[0].id);
        }
    }, [data]);

    useEffect(() => {
        if (myWorkzones?.length > 0) {
            let selectedWorkzoneId = myWorkzones[0].id;
            if (params.workzoneId !== undefined) {
                selectedWorkzoneId = params.workzoneId;
            }
            setWorkzoneId(selectedWorkzoneId);
        }
    }, [myWorkzones]);

    useEffect(() => {
        if (!data?.find((x) => x.id === parseInt(categoryId, 10))?.orderTypes.some((x) => x.id === typeId)) {
            setTypeId(data?.find((x) => x.id === parseInt(categoryId, 10))?.orderTypes[0].id);
        }
    }, [categoryId]);

    const saveOrder = async (status) => {
        let orderId = params.orderId;
        let data = {
            workzoneId,
            orderTypeId: typeId,
            executorWorkzoneId: executorWorkzoneId > 0 ? executorWorkzoneId : null,
            quantity,
            executionPeriod,
            cost,
            description,
            patientId: patientId > 0 ? patientId : null,
            status,
        };

        if (params.orderId > 0) {
            data.id = params.orderId;
            await updateOrder(data);
        } else {
            let createdOrder = await createOrder(data);
            orderId = createdOrder.data.id;
        }

        if (mrtFile !== null) {
            uploadFile({
                orderId,
                typeId: 2,
                formFile: mrtFile,
            });
        }
        if (orderDetails.length > 0) {
            orderDetails.map((item) => {
                console.log('item', item);
                if (item.id > 0) {
                    updateDetail({ ...item, orderId, teeth: JSON.stringify(item.teeth) });
                } else {
                    createDetail({ ...item, orderId, teeth: JSON.stringify(item.teeth) });
                }
            });
        }
        if (status === 8) {
            setIsOpenChangeStateModal(false);
            setNeedZIndex(true);
            setIsCreatedOpenModal(true);
        } else {
            handleClose();
        }
    };

    const handleClose = () => navigate(`/workzone/${params.workzoneId}/plane`);

    const handleSetFile = async (event) => {
        const { files } = event.target;
        var data = new FormData();
        for (const file of files) {
            data.append('formFile', file);
        }
        setMrtFile(data);
    };

    const changeToothHandle = (nmb) => {
        setChangedTeeth([nmb]);
        setChangedDetailIndex(-1);
        setChangedComments('');
        setNeedZIndex(true);
        setIsOpenDetailsModal(true);
    };

    const closeDetails = () => {
        setNeedZIndex(false);
        setIsOpenDetailsModal(false);
    };

    const saveOrderDetails = (item, index) => {
        if (index >= 0) {
            const upd = orderDetails.map((itm, indx) => {
                if (indx === index) {
                    itm.description = item.description;
                    itm.teeth = item.teeth;
                }
                return itm;
            });
            setOrderDetails(upd);
        } else {
            setOrderDetails([...orderDetails, item]);
        }
        setNeedZIndex(false);
        setIsOpenDetailsModal(false);
    };

    const deleteDetailTooth = (index, tooth) => {
        const upd = orderDetails.map((item, indx) => {
            if (index === indx) {
                item.teeth = item.teeth.filter((x) => x !== tooth);
            }
            return item;
        });
        setOrderDetails(upd);
    };

    const openDeleteDetailModal = (index) => {
        setIndexDeleteDetails(index);
        setIsOpenDeleteDetailsModal(true);
        setNeedZIndex(true);
    };

    const deleteHandle = () => {
        if (orderDetails[indexDeleteDetails].id > 0) {
            deleteDetail(orderDetails[indexDeleteDetails].id);
        }
        const upd = orderDetails.filter((item, index) => {
            if (indexDeleteDetails !== index) {
                return item;
            }
        });
        setIsOpenDeleteDetailsModal(false);
        setNeedZIndex(false);
        setOrderDetails(upd);
    };

    const editDetails = (index) => {
        setChangedTeeth(orderDetails[index].teeth);
        setChangedDetailIndex(index);
        setChangedComments(orderDetails[index].description);
        setNeedZIndex(true);
        setIsOpenDetailsModal(true);
    };

    return (
        <>
            <AddDetailsModal
                isOpen={isOpenDetailsModal}
                closeModal={closeDetails}
                toothNumbers={changedTeeth}
                save={saveOrderDetails}
                index={changedDetailIndex}
                comments={changedComments}
            />
            <DeleteDetailsModal
                isOpen={isOpenDeleteDetailsModal}
                closeModal={() => {
                    setIsOpenDeleteDetailsModal(false);
                    setNeedZIndex(false);
                }}
                deleteHandle={deleteHandle}
            />
            <AfterCreateOrderModal isOpen={isCreatedOpenModal} closeModal={handleClose} />
            <ChangeSaveStatusOrderModal
                isOpen={isOpenChangeStateModal}
                closeModal={() => {
                    setIsOpenChangeStateModal(false);
                    setNeedZIndex(false);
                }}
                createHandle={() => saveOrder(0)}
                saveHandle={() => saveOrder(8)}
            />
            <Modal
                show={true}
                onHide={handleClose}
                backdrop='static'
                size='xl'
                className='create-order-modal'
                style={needZIndex ? { zIndex: 9 } : null}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Создание заявки</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={7}>
                            <div className='modal-card mb-3'>
                                <div className='card-title'>Основная информация</div>
                                <Form.Group as={Row} className='mb-3' controlId='formWorkzone'>
                                    <Form.Label column md={labelMb}>
                                        Рабочая зона
                                    </Form.Label>
                                    <Col md={inputMb}>
                                        <Form.Select value={workzoneId} onChange={(e) => setWorkzoneId(e.target.value)}>
                                            {myWorkzones?.map((item) => (
                                                <option key={item.id} value={item.id}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className='mb-3' controlId='formCategory'>
                                    <Form.Label column md={labelMb}>
                                        Категория
                                    </Form.Label>
                                    <Col md={inputMb}>
                                        <Form.Select value={categoryId} onChange={(e) => setCategoryId(e.target.value)}>
                                            {data?.map((item) => (
                                                <option key={item.id} value={item.id}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className='mb-3' controlId='formType'>
                                    <Form.Label column md={labelMb}>
                                        Вид работы
                                    </Form.Label>
                                    <Col md={inputMb}>
                                        <Form.Select value={typeId} onChange={(e) => setTypeId(e.target.value)}>
                                            {data
                                                ?.find((x) => x.id === parseInt(categoryId, 10))
                                                ?.orderTypes?.map((item) => (
                                                    <option key={item.id} value={item.id}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className='mb-3' controlId='formName'>
                                    <Form.Label column md={labelMb}>
                                        Тип работы
                                    </Form.Label>
                                    <Col md={inputMb}>
                                        <Form.Control
                                            type='text'
                                            defaultValue={
                                                data
                                                    ?.find((x) => x.id === parseInt(categoryId, 10))
                                                    ?.orderTypes?.find((x) => x.id === parseInt(typeId, 10))?.shortName
                                            }
                                            disabled={true}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className='mb-3' controlId='formExecutor'>
                                    <Form.Label column md={labelMb}>
                                        Исполнитель
                                    </Form.Label>
                                    <Col md={inputMb}>
                                        <Form.Select
                                            value={executorWorkzoneId}
                                            onChange={(e) => setExecutorWorkzoneId(e.target.value)}
                                        >
                                            <option value={0}>Выберите</option>
                                            {favorites?.length > 0 ? (
                                                favorites?.map((item) => (
                                                    <option key={item.id} value={item.id}>
                                                        {item.workzone.name}
                                                    </option>
                                                ))
                                            ) : (
                                                <option value={0}>Нет элементов для выбора</option>
                                            )}
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className='mb-3' controlId='formExecutor'>
                                    <Form.Label column md={labelMb}>
                                        Пациент
                                    </Form.Label>
                                    <Col md={inputMb}>
                                        {workzonesLoading || workzoneId === undefined ? (
                                            <select className='form-select'></select>
                                        ) : (
                                            <DropdownInput
                                                workzoneId={workzoneId}
                                                handleIsOpenModal={(value) => setNeedZIndex(value)}
                                                handleChangeValue={(value) => setPatientId(value)}
                                                selectedValue={patientId}
                                            />
                                        )}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className='mb-3' controlId='formDetails'>
                                    <Form.Label column md={labelMb}>
                                        Детали
                                    </Form.Label>
                                    <Col md={inputMb} className='text-description'>
                                        {orderDetails?.length > 0
                                            ? orderDetails.map((item, index) => (
                                                  <DetailsPanel
                                                      detail={item}
                                                      key={index}
                                                      deleteTooth={(value) => deleteDetailTooth(index, value)}
                                                      deleteAll={() => openDeleteDetailModal(index)}
                                                      edit={() => editDetails(index)}
                                                  />
                                              ))
                                            : 'Чтобы добавить детали к заявке, выберите зуб на картинке справа'}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className='mb-3' controlId='formQuantity'>
                                    <Form.Label column md={labelMb}>
                                        Количество единиц
                                    </Form.Label>
                                    <Col md='3'>
                                        <Form.Control
                                            type='number'
                                            value={quantity}
                                            onChange={(e) => setQuantity(e.target.value)}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className='mb-3' controlId='formCost'>
                                    <Form.Label column md={labelMb}>
                                        Стоимость за единицу, ₽
                                    </Form.Label>
                                    <Col md='3'>
                                        <Form.Control
                                            type='number'
                                            value={cost}
                                            onChange={(e) => setCost(e.target.value)}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className='mb-3' controlId='formQuantity'>
                                    <Form.Label column md={labelMb}>
                                        Общая Стоимость, ₽
                                    </Form.Label>
                                    <Col md='3'>
                                        <Form.Control type='text' value={quantity * cost} disabled />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className='mb-3' controlId='formPeriod'>
                                    <Form.Label column md={labelMb}>
                                        Срок выполнения работы
                                    </Form.Label>
                                    <Col md={inputMb}>
                                        <div>
                                            <Form.Check
                                                inline
                                                label='До 10 дней'
                                                name='group1'
                                                type='radio'
                                                id='inline-radio-0'
                                                value={2}
                                                onChange={(e) => setExecutionPeriod(parseInt(e.target.value))}
                                                checked={executionPeriod === 2}
                                            />
                                            <Form.Check
                                                inline
                                                label='1-3 дня'
                                                name='group1'
                                                type='radio'
                                                id='inline-radio-1'
                                                value={1}
                                                onChange={(e) => setExecutionPeriod(parseInt(e.target.value))}
                                                checked={executionPeriod === 1}
                                            />
                                            <Form.Check
                                                inline
                                                label={
                                                    <>
                                                        Срочно <span className='text-muted'>(24 часа)</span>
                                                    </>
                                                }
                                                name='group1'
                                                type='radio'
                                                id='inline-radio-2'
                                                value={0}
                                                onChange={(e) => setExecutionPeriod(parseInt(e.target.value))}
                                                checked={executionPeriod === 0}
                                            />
                                        </div>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className='mb-5' controlId='formDescription'>
                                    <Form.Label column md={labelMb}>
                                        Описание
                                    </Form.Label>
                                    <Col md={inputMb}>
                                        <Form.Control
                                            as='textarea'
                                            rows={5}
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                        />
                                    </Col>
                                </Form.Group>
                                <Row>
                                    <Col>
                                        <Button
                                            variant='outline-primary me-2'
                                            className='px-3 py-1 rounded-2'
                                            onClick={() => saveOrder(8)}
                                        >
                                            Сохранить
                                        </Button>
                                        <Button
                                            variant='primary'
                                            className='px-3 py-1 rounded-2'
                                            onClick={() => {
                                                setIsOpenChangeStateModal(true);
                                                setNeedZIndex(true);
                                            }}
                                        >
                                            Создать
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                            <div className='modal-card'>
                                <div className='card-title'>Прикрепить файлы</div>
                                {params.orderId > 0 ? (
                                    <OrderFiles orderId={params.orderId} />
                                ) : (
                                    <div className='text-color-gray font-size-13'>
                                        <i>Прикрепить файлы возможно только после сохранения заявки</i>
                                    </div>
                                )}
                            </div>
                        </Col>
                        <Col>
                            <div className='modal-card'>
                                <div className='card-title text-center'>Детали</div>
                                <div className='tooth-panel'>
                                    <div className='top-numbers d-flex justify-content-evenly mb-3'>
                                        <div className='tooth-numb' onClick={() => changeToothHandle(13)}>
                                            13
                                        </div>
                                        <div className='tooth-numb' onClick={() => changeToothHandle(12)}>
                                            12
                                        </div>
                                        <div className='tooth-numb' onClick={() => changeToothHandle(11)}>
                                            11
                                        </div>
                                        <div className='tooth-numb' onClick={() => changeToothHandle(21)}>
                                            21
                                        </div>
                                        <div className='tooth-numb' onClick={() => changeToothHandle(22)}>
                                            22
                                        </div>
                                        <div className='tooth-numb' onClick={() => changeToothHandle(23)}>
                                            23
                                        </div>
                                    </div>
                                    <div className='mid-numbers d-flex justify-content-between mb-3'>
                                        <div className='left-panel'>
                                            <div className='tooth-numb' onClick={() => changeToothHandle(14)}>
                                                14
                                            </div>
                                            <div className='tooth-numb' onClick={() => changeToothHandle(15)}>
                                                15
                                            </div>
                                            <div className='tooth-numb' onClick={() => changeToothHandle(16)}>
                                                16
                                            </div>
                                            <div className='tooth-numb' onClick={() => changeToothHandle(17)}>
                                                17
                                            </div>
                                            <div className='tooth-numb' onClick={() => changeToothHandle(18)}>
                                                18
                                            </div>
                                        </div>
                                        <div className='center-panel align-self-center'>
                                            <img src='/images/icons/tooth-up.svg' />
                                        </div>
                                        <div className='right-panel'>
                                            <div className='tooth-numb' onClick={() => changeToothHandle(24)}>
                                                24
                                            </div>
                                            <div className='tooth-numb' onClick={() => changeToothHandle(25)}>
                                                25
                                            </div>
                                            <div className='tooth-numb' onClick={() => changeToothHandle(26)}>
                                                26
                                            </div>
                                            <div className='tooth-numb' onClick={() => changeToothHandle(27)}>
                                                27
                                            </div>
                                            <div className='tooth-numb' onClick={() => changeToothHandle(28)}>
                                                28
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mid-numbers d-flex justify-content-between mb-3'>
                                        <div className='left-panel'>
                                            <div className='tooth-numb' onClick={() => changeToothHandle(48)}>
                                                48
                                            </div>
                                            <div className='tooth-numb' onClick={() => changeToothHandle(47)}>
                                                47
                                            </div>
                                            <div className='tooth-numb' onClick={() => changeToothHandle(46)}>
                                                46
                                            </div>
                                            <div className='tooth-numb' onClick={() => changeToothHandle(45)}>
                                                45
                                            </div>
                                            <div className='tooth-numb' onClick={() => changeToothHandle(44)}>
                                                44
                                            </div>
                                        </div>
                                        <div className='center-panel align-self-center'>
                                            <img src='/images/icons/tooth-down.svg' />
                                        </div>
                                        <div className='right-panel'>
                                            <div className='tooth-numb' onClick={() => changeToothHandle(38)}>
                                                38
                                            </div>
                                            <div className='tooth-numb' onClick={() => changeToothHandle(37)}>
                                                37
                                            </div>
                                            <div className='tooth-numb' onClick={() => changeToothHandle(36)}>
                                                36
                                            </div>
                                            <div className='tooth-numb' onClick={() => changeToothHandle(35)}>
                                                35
                                            </div>
                                            <div className='tooth-numb' onClick={() => changeToothHandle(34)}>
                                                34
                                            </div>
                                        </div>
                                    </div>
                                    <div className='bottom-numbers d-flex justify-content-evenly'>
                                        <div className='tooth-numb' onClick={() => changeToothHandle(43)}>
                                            43
                                        </div>
                                        <div className='tooth-numb' onClick={() => changeToothHandle(42)}>
                                            42
                                        </div>
                                        <div className='tooth-numb' onClick={() => changeToothHandle(41)}>
                                            41
                                        </div>
                                        <div className='tooth-numb' onClick={() => changeToothHandle(31)}>
                                            31
                                        </div>
                                        <div className='tooth-numb' onClick={() => changeToothHandle(32)}>
                                            32
                                        </div>
                                        <div className='tooth-numb' onClick={() => changeToothHandle(33)}>
                                            33
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default CreateOrderModal;
