import { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useCreateWorkzoneMutation } from '../../../app/api/workzoneApiSlice';

const WorkzoneCreateModal = () => {
    const [createWorkzone] = useCreateWorkzoneMutation();

    const [show, setShow] = useState(false);
    const [name, setName] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const save = () => {
        createWorkzone({ name });
        handleClose();
    };

    return (
        <>
            <button className='btn btn-primary' onClick={handleShow}>
                + Создать рабочую зону
            </button>

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Создание рабочей зоны</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group
                        className='mb-3'
                        controlId='exampleForm.ControlInput1'
                    >
                        <Form.Label>Название рабочей зоны</Form.Label>
                        <Form.Control
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='primary' onClick={save}>
                        Создать
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default WorkzoneCreateModal;
