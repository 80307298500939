import React, { useEffect, useState } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import { useGetMyPatientsQuery } from '../../../app/api/patientApiSlice';
import AddPatientModal from './AddPatientModal';

const CustomToggle = React.forwardRef(({ title, onClick }, ref) => (
    <input
        className='form-select cursor-pointer'
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
        value={title || ''}
        readOnly
    />
));

const CustomMenu = React.forwardRef(({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    return (
        <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
            <ul className='list-unstyled mb-0'>{React.Children.toArray(children)}</ul>
        </div>
    );
});

const DropdownInput = ({ workzoneId, handleIsOpenModal, handleChangeValue, selectedValue }) => {
    const { data, isLoading } = useGetMyPatientsQuery(workzoneId);

    const [isOpenPatientModal, setIsOpenPatientModal] = useState(false);
    const [value, setValue] = useState('');

    useEffect(() => {
        if (!isLoading && !data?.some((x) => x.id === selectedValue)) {
            handleChangeValue(0);
        }
    }, [data]);

    const openCreatePatient = () => {
        handleIsOpenModal(true);
        setIsOpenPatientModal(true);
    };

    const closeCreatePatient = (patientId) => {
        handleChangeValue(patientId);
        handleIsOpenModal(false);
        setIsOpenPatientModal(false);
    };

    return (
        <>
            <AddPatientModal isOpen={isOpenPatientModal} closeModal={closeCreatePatient} workzoneId={workzoneId} />
            <Dropdown className='position-relative'>
                <Dropdown.Toggle
                    as={CustomToggle}
                    id='dropdown-custom-components'
                    title={data?.find((x) => x.id === selectedValue)?.lastName ?? 'Не выбрано'}
                />

                <Dropdown.Menu as={CustomMenu} className='dropdown-input-menu w-100'>
                    <Form.Control
                        autoFocus
                        className='mx-2 my-2 w-75'
                        placeholder='Введите фамилию...'
                        onChange={(e) => setValue(e.target.value)}
                        value={value}
                    />
                    <div className='dropdown-patients-block'>
                        <Dropdown.Item as='button' onClick={() => handleChangeValue(0)}>
                            Не выбрано
                        </Dropdown.Item>
                        {data
                            ?.filter((x) => x.lastName.toLowerCase().includes(value.toLowerCase()))
                            .map((item) => (
                                <Dropdown.Item as='button' key={item.id} onClick={() => handleChangeValue(item.id)}>
                                    {item.lastName}
                                </Dropdown.Item>
                            ))}
                    </div>
                    <Dropdown.Item as='button' className='patient-add-btn' onClick={openCreatePatient}>
                        <img src='/images/icons/plus.svg' height={16} className='align-top' />
                        <span>Создать новго пациента</span>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
};

export default DropdownInput;
