import { Col } from 'react-bootstrap';
import BtnMenuCol from './BtnMenuCol';
import { Link } from 'react-router-dom';
import OrderCard from './OrderCard';
import { useEffect, useState } from 'react';
import AfterDeleteOrderModal from '../orders/AfterDeteleOrderModal';

const PlaneColumn = (props) => {
    const { orders, colId, colTitle, workzoneId } = props;

    // 0 - закрыто, 1 - только заголовок, 3 - открыто
    const [panelState, setPanelState] = useState(3);
    const [afterDeleteIsOpenModal, setAfterDeleteIsOpenModal] = useState(false);
    const [deletedOrderId, setDeletedOrderId] = useState(0);
    const [sort, setSort] = useState({ id: 0, type: 'createdDate', direction: 0 });
    const [filterCategories, setFilterCategories] = useState([]);
    const [filterTypes, setFilterTypes] = useState([]);

    useEffect(() => {
        let preState = localStorage.getItem(`collapsedState_${workzoneId}_${colId}`) ?? 3;
        setPanelState(parseInt(preState));
    }, [workzoneId]);

    const changePanelState = (stateId) => {
        setPanelState(stateId);
        localStorage.setItem(`collapsedState_${workzoneId}_${colId}`, stateId);
    };

    const handleAfterDelete = (orderId) => {
        setDeletedOrderId(orderId);
        setAfterDeleteIsOpenModal(true);
    };

    return (
        <>
            <AfterDeleteOrderModal
                isOpen={afterDeleteIsOpenModal}
                closeModal={() => setAfterDeleteIsOpenModal(false)}
                orderId={deletedOrderId}
            />
            {panelState === 3 ? (
                <Col md={3} className='col-panel-state'>
                    <div className='panel-state'>
                        <div className='panel-state-header d-flex justify-content-between'>
                            <div className='header-title align-self-center'>{colTitle}</div>
                            <BtnMenuCol
                                changeState={() => changePanelState(0)}
                                changeSort={setSort}
                                filterCategories={setFilterCategories}
                                filterTypes={setFilterTypes}
                            />
                        </div>
                        <div className='panel-state-body pb-1'>
                            {orders?.length === 0 && colId === 0 ? (
                                <Link className='card-order-add mb-5 d-block text-decoration-none' to={'orders/0'}>
                                    <img src='/images/icons/plus.svg' height={16} className='me-1 align-top' />
                                    <span>Добавить проект</span>
                                </Link>
                            ) : null}
                            {orders?.length === 0 && colId === 1 ? (
                                <div className='card-order-add mb-5'>
                                    <img src='/images/icons/plus.svg' height={16} className='me-1 align-top' />
                                    <span>Найти проект</span>
                                </div>
                            ) : null}
                            {orders
                                ?.filter((value) => {
                                    if (filterCategories.length === 0) {
                                        return value;
                                    } else {
                                        return filterCategories.some((x) => x === value.orderType.orderCategoryId);
                                    }
                                })
                                ?.filter((value) => {
                                    if (filterTypes.length === 0) {
                                        return value;
                                    } else {
                                        return filterTypes.some((x) => x === value.orderType.shortName);
                                    }
                                })
                                ?.sort((first, second) => {
                                    switch (sort.type) {
                                        case 'createdDate':
                                            if (sort.direction == 0) {
                                                return new Date(first.createdAt) - new Date(second.createdAt);
                                            } else {
                                                return new Date(second.createdAt) - new Date(first.createdAt);
                                            }
                                        case 'executionDate':
                                            if (sort.direction == 0) {
                                                return new Date(first.executionDate) - new Date(second.executionDate);
                                            } else {
                                                return new Date(second.executionDate) - new Date(first.executionDate);
                                            }
                                        case 'alphabet':
                                            console.log('alphabet');
                                            if (sort.direction == 0) {
                                                return (first.patient?.lastName.toUpperCase() ?? '') <
                                                    (second.patient?.lastName.toUpperCase() ?? '')
                                                    ? -1
                                                    : 1;
                                            } else {
                                                return (second.patient?.lastName.toUpperCase() ?? '') <
                                                    (first.patient?.lastName.toUpperCase() ?? '')
                                                    ? -1
                                                    : 1;
                                            }
                                        case 'orderNumber':
                                            if (sort.direction == 0) {
                                                return first.id - second.id;
                                            } else {
                                                return second.id - first.id;
                                            }
                                        default:
                                            break;
                                    }
                                })
                                ?.sort((first, second) => {
                                    return first.isPinned === second.isPinned ? 0 : first.isPinned ? -1 : 1;
                                })
                                .map((order) => (
                                    <OrderCard
                                        order={order}
                                        key={order.id}
                                        deletedOrder={(orderId) => handleAfterDelete(orderId)}
                                    />
                                ))}
                        </div>
                    </div>
                </Col>
            ) : (
                <div className='collapsed align-item-center'>
                    {panelState === 1 ? (
                        <div className='collapsed-header-title d-inline-block'>
                            {colTitle}{' '}
                            <button className='btn' onClick={() => changePanelState(3)}>
                                <img src='/images/icons/eye.svg' height={16} />
                            </button>
                        </div>
                    ) : null}
                    <button className='btn btn-collapse' onClick={() => changePanelState(panelState === 0 ? 1 : 0)}>
                        <img
                            src={'/images/icons/' + (panelState === 0 ? 'arrow-right.svg' : 'arrow-left.svg')}
                            height={16}
                        />
                    </button>
                </div>
            )}
        </>
    );
};

export default PlaneColumn;
